import { Injectable } from '@angular/core';
import {
  HttpResponse,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoaderService } from '../services/loader.service';
import { TokenService } from '../services/token.service';
import { JwtauthService } from '../services/jwtauth.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private requests: HttpRequest<any>[] = [];

  constructor(
    private tokenSer: TokenService,
    private jwtAuthSer: JwtauthService,
    private loaderService: LoaderService
    ) { }

  removeRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }
    this.processGlobalLoader(req, this.requests.length > 0);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    this.processGlobalLoader(req, true);
    req = req.clone({
      headers: new HttpHeaders({
        'Authorization': 'Bearer '+this.tokenSer.get_bearer()
      })
    });

    this.requests.push(req);
    // console.log("No of requests--->" + this.requests.length);

    return Observable.create(observer => {
      const subscription = next.handle(req)
        .subscribe(
          event => {
            if (event instanceof HttpResponse) {
              this.removeRequest(req);
              observer.next(event);
            }
          },
          err => {
            this.jwtAuthSer.handleError(err);
            if (err.status == 409) {
              this.loaderService.notifySourceError.next(err?.error?.lang_key);
            }
            // alert('error' + err);
            this.removeRequest(req);
            observer.error(err);
          },
          () => {
            this.removeRequest(req);
            observer.complete();
          });
      // remove request from queue when cancelled
      return () => {
        this.removeRequest(req);
        subscription.unsubscribe();
      };
    });
  }

  processGlobalLoader(req, show) {
    var uris = [
      'project/client-quote/get',
      'project/generate-invoice/html',
      'project/generate-invoice/pdf'
    ];
    /* Enabled only for selected API calls */
    if ( show && ! new RegExp(uris.join('|')).test(req.url) ) {
      return;
    }
    this.loaderService.isLoadingSource.next(show);
  }
}
