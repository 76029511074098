import {Component, OnInit, TemplateRef,Injector, ViewChild} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';
import { UntypedFormBuilder, FormArray, FormControl } from '@angular/forms';
import { TokenService } from '../services/token.service';
import { JwtauthService } from '../services/jwtauth.service';
import { Router } from '@angular/router';
import { HelperService } from '../services/helper.service';
import { LanguageService } from '../services/language.service';
import { HelpModalComponent } from '../components/help-modal/help-modal.component';
import { ConfigureService } from '../modules/configure/services/configure.service';
import { ReleaseNotesModalComponent } from '../components/release-notes-modal/release-notes-modal.component';
import { DashboardDisplaySettingsComponent } from './components/dashboard-display-settings/dashboard-display-settings.component';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss']
})
export class AppHeaderComponent implements OnInit {
  private apiURL = '';
  publicURL;
  public name;
  public image;
  public type = 'config';
  pageUrl = '';
  modalRef: BsModalRef;
  userId;
  userEmail;
  lang;
  langForm;
  userDetails;
  usedStoragePer: number = 0;
  public screenWidth: any;  
  @ViewChild('langPopup', {static: true}) langPopup:TemplateRef<any>;

  constructor(
    private injector: Injector,
    private http: HttpClient,
    private Token: TokenService,
    public jwtAuth: JwtauthService,
    private router: Router,
    public helpSer: HelperService,
    private modalService: BsModalService,
    private fb: UntypedFormBuilder,
    public language: LanguageService,
    private configureSer: ConfigureService
    ) {
    this.apiURL = this.injector.get('API_URL');
    this.publicURL = this.injector.get('PUBLIC_URL');
    this.router.events.subscribe((e) => {
        this.pageUrl = this.router.url.toString();
    });
    this.lang = language;
   }

  ngOnInit() {
    this.screenWidth = window.innerWidth;  
    this.userDetails = this.jwtAuth.getMyDetails();
    this.handleResponse(this.userDetails);
    this.langForm = this.fb.group({
      lang: [{
        value: this.lang.getMyLang(),
        // disabled: true
      }]
    });
  }

  ngAfterViewInit() {
    this.getStorageDetails();
    if ( ! this.jwtAuth.alreadyShownReleaseNote() ) {
      this.openReleaseNoteModal();
      return;
    }
    this.openLangPopup(false);
    setTimeout(() => {
      const defaultLang = this.lang.getMyLang(); 
      this.langForm.patchValue({ lang: defaultLang });
    }, 2000);
  }

  logoutMe() {
    this.jwtAuth.logout();
  }

  loggedIn() {
    return this.Token.loggedIn();
  }

  handleResponse(data) {
    this.image = "";
    this.name = data.name;
    this.userEmail = data.email;
    this.userId = data.id;
    this.http.post(`${this.apiURL+this.type}s`, this.Token.getAPITokenData()).subscribe(
      datas => {
        if (datas['logo'] && datas['logo'].temporary_url) {
          this.image = datas['logo'].temporary_url;
        } else {
          this.image = this.publicURL+"logo/logo.png";
        }
         if(datas['result'] && datas['result'] != '' && datas['result'] != null && Object.keys(datas['result']).length > 0){
             for(var i in datas['result']){
                if(datas['result'][i].name == "Company Logo" && datas['result'][i].value != "LOGO"){
                  // this.image = datas['result'][i].value;
                  // this.image = this.publicURL+"logo/logo.png";
                  break;
                }
             }
        }
      },
      error => this.handleError(error)
    );
  }



  handleError(error) {
    this.jwtAuth.handleError(error);
  }
  title = 'OOMS 2.0';

  openLangPopup(force: Boolean) {
    if ( ! force && localStorage.getItem('hideLangSelectionPopup') == 'Yes') {
      return;
    }
    this.modalRef = this.modalService.show(this.langPopup, {class: "modal-lg new-redesign-style"});
  }
  
  closeModal() {
    this.modalRef.hide();
    localStorage.setItem('hideLangSelectionPopup', 'Yes');
  }
  enableLang(){
    let lang = this.langForm.get('lang');
    lang.enable();
  }
  disableLang(){
    let lang = this.langForm.get('lang');
    lang.disable();
  }
  changeLang(){
    this.closeModal();
    let lang = this.langForm.get('lang');
    let user = 'user';
    if(this.jwtAuth.isVenodor())
      user = 'vendor';
    // if(!lang.disabled ){
      this.lang.changeLanguage(lang.value, user ).subscribe(
        data => {
          // this.disableLang();
          let navUrl = this.jwtAuth.isVenodor() ? '/vendor/dashboard' : '/project' ;
          if(this.router.url == navUrl) {
            window.location.reload();
          } else {
            window.location.href = navUrl;
          }
        },
        error => this.handleError(error)
      );
    // }
  }

  @ViewChild('helpModalCom') helpModalCom: HelpModalComponent;
  openHelpModal() {
    this.helpModalCom.openHelpModal();
  }
  openAgreeModal() {
    this.helpModalCom.openAgreeModal();
  }
  
  enable_storage_alery_sec(): boolean {
    return this.jwtAuth.isSuperUser() && ((this.usedStoragePer >= 90 && this.usedStoragePer < 100) || this.usedStoragePer >= 100);
  }
  getStorageDetails() {
    if ( ! this.jwtAuth.isSuperUser() ) { return; }
    this.configureSer.storageDetails().subscribe(
      data => {
        this.usedStoragePer = this.helpSer.roundOfToFraction((((data['filesSize'] / Math.pow(1024, 4)) / data['totalStorage']) * 100), 2);
      }
    );
    this.langForm.patchValue({ lang: this.lang.getMyLang() });
  }

  @ViewChild('releaseNotesModalCom') releaseNotesModalCom: ReleaseNotesModalComponent;
  openReleaseNoteModal() {
    if (this.router.url.indexOf('vendor/privacy-policy') > -1) {
      return;
    }
    if ( localStorage.getItem('hideReleaeNoteSelectionPopup') == 'Yes' ) {
      return;
    }
    this.releaseNotesModalCom.openModal();
  }
  
  closeReleaseNoteModal() {
    localStorage.setItem('hideReleaeNoteSelectionPopup', 'Yes');
    setTimeout(()=>{
      this.openLangPopup(false)
    }, 500);
  }

  @ViewChild('dashboardDisplaySettingsCom') dashboardDisplaySettingsCom: DashboardDisplaySettingsComponent;
  openDashboardSettingsPopup() {
    this.dashboardDisplaySettingsCom.openModal();
  }

  hide_screen_width_ls_key = 'hide_screen_width_alert';
  show_screen_width_alert() {
    return this.screenWidth < 1280 && localStorage.getItem(this.hide_screen_width_ls_key) != 'Yes'
  }

  hide_screen_width_alert() {
    localStorage.setItem(this.hide_screen_width_ls_key, 'Yes');
  }
}
