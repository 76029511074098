<div class="row col-12 row-secondary p-2">
  <div class="col-md-2 col-sm-12 nospace">{{ lang.getText('source_language') }}</div>
  <div class="col-md-2 col-sm-12 nospace">{{ lang.getText('target_language') }}</div>
  <div class="col-md-1 col-sm-12 nospace">{{ lang.getText('word_rate') }}</div>
  <div class="col-md-2 col-sm-12 nospace">{{ lang.getText('description_with_colon') }} {{lang.getText('optional')}}</div>
  <div class="col-md-3 col-sm-12 nospace">{{ lang.getText('subtotal_combo') }}</div>
  <div class="col-md-2 col-sm-12 nospace">{{ lang.getText('edit') }}</div>
</div>

<div class="row" formArrayName="translations" *ngFor="let trans of projectEditForm.get('translations')['controls']; let i = index">
  <div class="row-primary row col-12 align-items-center p-0 mb-1" *ngIf="!trans.getRawValue().display"
  [hidden]="!trasnlation_pagination_data.quote_show_hide_financially_closed && trans.get('financially_closed').value">
    <div class="col-md-2 col-sm-12 nospace text-center">
      <span class="ps-4 float-start">
        <span *ngIf="trans.getRawValue().invoiceable" class="color-success">
          <em class="fa fa-check" aria-hidden="true"></em>
        </span>
      </span>
      <span>{{getLanguageName(trans.get('source_lang_id').value)}}</span>
    </div>
    <div class="col-md-2 col-sm-12 nospace">{{getLanguageName(trans.get('target_lang_id').value)}}</div>
    <div class="col-md-1 col-sm-12 nospace">{{trans.get('word_rate').value}}</div>
    <div class="col-md-2 col-sm-12 nospace">{{trans.get('comment').value}}</div>
    <div class="col-md-3 col-sm-12 nospace d-flex align-items-center">
      <app-total-display 
        title = ""
        [amount] = "trans.get('amount').value"
        [currencyCode] = "projectCurrencyName"
        type = ""
        col_l = "4"
        col_r = "8" style="width: 100%;"
      ></app-total-display>
    </div>
    <div class="col-md-2 col-sm-2 nospace">
      <div class="row">
        <div class="col-lg-12 col-md-6 col-sm-12 pe-2">
          <span class="btn btn-border-secondary btn-circle color-primary" *ngIf="!projectEditDis"
              (click)="checkClaimed ? copyThisResTran(i) : false">
              <svg style="width: 13px;"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M320 448v40c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V120c0-13.3 10.7-24 24-24h72v296c0 30.9 25.1 56 56 56h168zm0-344V0H152c-13.3 0-24 10.7-24 24v368c0 13.3 10.7 24 24 24h272c13.3 0 24-10.7 24-24V128H344c-13.2 0-24-10.8-24-24zm121-31L375 7A24 24 0 0 0 358.1 0H352v96h96v-6.1a24 24 0 0 0 -7-17z" fill="#18347b"/></svg>
            </span>
          <span class="btn btn-border-secondary btn-circle color-primary" *ngIf="!projectEditDis"
              (click)="checkClaimed ? displayThisResTran(i) : false"><i class="fa fa-pencil"
              aria-hidden="true"></i></span>
          <span class="btn btn-border-secondary btn-circle color-secondary me-2" *ngIf="!projectEditDis"
            (click)="checkClaimed && checkTradosData && !checkXTMData ? deleteClientTran(trans, i) : false"><i class="fa fa-trash" [class.disabled]="!checkTradosData || checkXTMData"
              aria-hidden="true"></i></span>
        </div>
    </div>
   </div>
  </div>
  
  <div
    [hidden]="!trasnlation_pagination_data.quote_show_hide_financially_closed && trans.get('financially_closed').value"
    [formGroupName]="i"
    *ngIf="trans.getRawValue().display && trans.getRawValue().delete != 'yes'"
    [ngClass]="{ 'selected' : trans.getRawValue().display }"
    class="row col-12 res-trans-row"
    id="quote_trans_{{i+1}}">
      <input type="hidden" formControlName="delete" />
      <input type="hidden" formControlName="display" />
      <div class="row ms-2 ps-2">
        <div class="col-md-2 col-sm-12 nospace">
          <div class="form-check form-switch">
            <label class="d-flex align-items-center">
              <input class="form-check-input me-2" formControlName="invoiceable" type="checkbox">
              {{ lang.getText('display_in_quote') }}
            </label>
          </div>
        </div>
        <div class="col-md-2 col-sm-12 nospace">
          <div class="form-check form-switch">
            <label class="d-flex align-items-center"
              [class.disabled]="checkXTMData && trans.get('jobStatus').value != 'FINISHED' ? true : false">
              <input class="form-check-input me-2" formControlName="financially_closed"
              type="checkbox"
              (change)="markTrasnlationFinanciallyClosed($event.target.checked, trans)">
              {{ lang.getText('financially_closed') }}
            </label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-2 col-sm-12">
          <span class="inner-label">{{lang.getText('source')}} {{lang.getText('language')}}</span>
          <!-- <select formControlName="source_lang_id" class="form-control" (change)="onSourceLanguageChange()">
            <option *ngFor="let language of languages; let li = index" value="{{language.id}}">{{language.name}}
              </option>
          </select> -->
          <ng-select (change)="onSourceLanguageChange()"
            [items]="languages"
            [clearable]="false"
            bindLabel="name" 
            bindValue="id"
            notFoundText="{{ 'no_data_found' | translate }}" 
            formControlName="source_lang_id">
          </ng-select>
        </div>
        <div class="col-md-2 col-sm-12">
          <span class="inner-label">{{lang.getText('target')}} {{lang.getText('language')}}</span>
            <!-- <select formControlName="target_lang_id" class="form-control">
              <option *ngFor="let language of languages; let li = index" value="{{language.id}}">{{language.name}}
              </option>
            </select> -->
            <ng-select
              [items]="languages" 
              [clearable]="false"
              bindLabel="name" 
              bindValue="id"
              notFoundText="{{ 'no_data_found' | translate }}" 
              formControlName="target_lang_id">
            </ng-select>
        </div>
        <div class="col-md-2 col-sm-12">
          <span class="inner-label">{{lang.getText('word_rate')}}</span>
          <input (keypress)="nonNumericValPressDecimal($event)" formControlName="word_rate" type="number"
            class="form-control">
        </div>
        <div class="col-md-2 col-sm-12">
          <span class="inner-label">{{lang.getText('description')}} {{lang.getText('optional')}}</span>
          <input formControlName="comment" type="text" class="form-control">
        </div>
        <div class="col-md-2 col-sm-12 align-content-end">
          <div class="row md_rw sub-total-col badge-empty" style="margin: 0px;">
            <div class="nospace text-center">
              <app-total-display 
                title = "subtotal"
                [amount] = "trans.getRawValue().amount"
                [currencyCode] = "projectCurrencyName"
                type = ""
                col_l = "6"
                col_r = "6"
              ></app-total-display>
              <input formControlName="amount" readonly type="hidden" min="0" class="form-control font-weight-bold">
            </div>
          </div>
        </div>
        <div class="col-md-2 col-sm-12 align-content-end text-center">
          <span class="btn btn-border-secondary btn-circle color-secondary" *ngIf="!projectEditDis"
            (click)="checkClaimed && checkTradosData && !checkXTMData ? deleteClientTran(trans, i) : false"><i class="fa fa-trash fa-lg" [class.disabled]="!checkTradosData || checkXTMData"
              aria-hidden="true"></i></span>
          <span class="btn btn-border-secondary btn-circle color-primary" *ngIf="!projectEditDis"
              (click)="checkClaimed ? displayThisResTran(i) : false"><i class="fa fa-pencil fa-lg"
              aria-hidden="true"></i></span>
        </div>
      </div>
      <div class="row nospace">
        <div class="col-md-12 nospace"
          *ngFor="let lnk_batch of trans.get('batches_links')['controls']">
          <p class="blue medium">{{lang.getText('batch_delivery_date')}}:
            {{ helperSer.formateDateObj({date: lnk_batch.get('requested_at').value }) }}
          </p>
        </div>
      </div>

      <div class="col-lg-12 col-md-12 col-sm-12 nospace">
        <div class="row">
          <ng-container *ngFor="let metas of trans.getRawValue().translation_meta_values">
            <ng-container *ngIf="diaplayMeta(metas) && metas.meta_key !== 'sap_xtm_financially_closed'">
              <ng-container *ngIf="metas.meta_key === 'sap_xtm_job_id'">
                <div class="trans-job col-md-2 col-sm-12 nospace">
                  <p class="medium">{{lang.getText(metas.meta_key)}}: {{metas.meta_value}}</p>
                </div>
              </ng-container>
              <ng-container *ngIf="metas.meta_key === 'sap_xtm_job_status'">
                <div class="trans-job col-md-2 col-sm-12 nospace">
                  <p class="medium">{{lang.getText(metas.meta_key)}}: {{metas.meta_value}}</p>
                </div>
              </ng-container>
              <ng-container *ngIf="metas.meta_key === 'sap_xtm_job_status_percent'">
                <div class="col-md-5 trans-job col-sm-12 nospace">
                  <p class="medium">{{lang.getText(metas.meta_key)}}: {{metas.meta_value}}{{ metas.meta_key == 'sap_xtm_job_status_percent' ? '%' : '' }}</p>
                  <div class="progress" *ngIf="metas.meta_key == 'sap_xtm_job_status_percent'">
                    <div
                      class="progress-bar progress-bar-striped {{ showProjectJobProgress['data'][metas.project_translate_id]['color'] }} {{'w-' + showProjectJobProgress['data'][metas.project_translate_id]['percentage'] }}"
                      role="progressbar"
                      attr.aria-valuenow="{{showProjectJobProgress['data'][metas.project_translate_id]['percentage']}}"
                      aria-valuemin="0" aria-valuemax="100"
                      [style.width]="showProjectJobProgress['data'][metas.project_translate_id]['percentage'] + '%'"></div>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="metas.meta_key === 'sap_xtm_job_steps'">
                <div class="trans-job col-md-2 col-sm-12 nospace">
                  <p class="medium">{{lang.getText(metas.meta_key)}}: {{metas.meta_value}}</p>
                </div>
              </ng-container>
              <ng-container *ngIf="metas.meta_key === 'sap_xtm_job_words_to_be_done' && metas.meta_value > 0">
                <div class="trans-job col-md-2 col-sm-12 nospace">
                  <p class="medium">{{lang.getText(metas.meta_key)}}: {{metas.meta_value}}</p>
                </div>
              </ng-container>
              <ng-container *ngIf="metas.meta_key === 'sap_xtm_job_file_name'">
                <div class="trans-job col-md-5 col-sm-12 nospace">
                  <p class="medium">{{lang.getText(metas.meta_key)}}: {{metas.meta_value}}</p>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </div>
      <div *ngIf="currency_id && trans.getRawValue().display && trans.getRawValue().delete != 'yes'" class="col-lg-7 col-md-8 col-sm-12 nospace mt-2">
        <div class="col-md-3 col-sm-12 nospace select-stored-rate-sec">
          <button (click)="select_rate(trans)" type="button" class="btn btn-primary btn-sm">{{'select_stored_rate' | translate}}</button>
        </div>
      </div>

      <div class="single_part_main cat_analysis mt-4">
        <div *ngIf="trans.getRawValue().display && trans.getRawValue().delete != 'yes'" class="single_part_inner">
          <div class="main_head">
            <p>{{lang.getText('cat_analysis_combination')}} {{((trasnlation_pagination_data.quote_page - 1) * trasnlation_pagination_data.quote_per_page) + i + 1}}</p>
          </div>
          <div class="row nospace">
            <div class="row nospace">
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 nospace">
                <div class="row">
                  <div class="col-md-3 col-sm-3"></div>
                  <div class="col-md-3 col-sm-3 text-center">
                    <p class="title">{{lang.getText('words')}}</p>
                  </div>
                  <div class="col-md-3 col-sm-3 text-center">
                    <p class="title">{{lang.getText('unit_rate')}}</p>
                  </div>
                  <div class="col-md-3 col-sm-3 text-center">
                    <p class="title">{{lang.getText('subtotal')}}</p>
                  </div>
                </div>
                <div formArrayName="catAnalysisGroA" class="col-12" *ngFor="let wordMatch of wordMatchesA; let i = index">
                  <div [formGroupName]="i" class="row" *ngIf="trans.get('catAnalysisGroA').at(i).getRawValue().display"> 
                    <input formControlName="word_match_id" type="hidden" value="{{wordMatch.id}}" />
                    <input formControlName="display" type="hidden" />
                    <input formControlName="percentage" type="hidden" />
                    <div class="col-md-3 col-sm-3 nospace badge-secondary">
                      <p>{{wordMatch.name}}</p>
                    </div>
                    <div class="col-md-3 col-sm-3">
                      <input appShadowCheck formControlName="word_count" (keypress)="trans.patchValue({is_cat_changed: true}); nonNumericValPressDecimal($event);" type="number" min="0"
                        class="text-center form-control words" [attr.disabled]="(!checkTradosData || checkXTMData || checkPrhaseData) ? '' : null">
                    </div>
                    <div class="col-md-3 col-sm-3">
                      <input appShadowCheck formControlName="rate" type="number" min="0" readonly class="text-center form-control unit_rate">
                    </div>
                    <div class="col-md-3 col-sm-3">
                      <input appShadowCheck formControlName="amount" type="number" min="0" readonly class="text-center form-control sub_total">
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 nospace">
                <div class="row">
                  <div class="col-md-3 col-sm-3"></div>
                  <div class="col-md-3 col-sm-3 text-center">
                    <p class="title">{{lang.getText('words')}}</p>
                  </div>
                  <div class="col-md-3 col-sm-3 text-center">
                    <p class="title">{{lang.getText('unit_rate')}}</p>
                  </div>
                  <div class="col-md-3 col-sm-3 text-center">
                    <p class="title">{{lang.getText('subtotal')}}</p>
                  </div>
                </div>
                <div formArrayName="catAnalysisGroB" class="col-12" *ngFor="let wordMatch of wordMatchesB; let i = index">
                  <div [formGroupName]="i" class="row" *ngIf="trans.get('catAnalysisGroB').at(i).getRawValue().display"> 
                    <input formControlName="word_match_id" type="hidden" value="{{wordMatch.id}}">
                    <input formControlName="display" type="hidden" />
                    <input formControlName="percentage" type="hidden" />
                    <div class="col-md-3 col-sm-3 nospace badge-secondary">
                      <p>{{wordMatch.name}}</p>
                    </div>
                    <div class="col-md-3 col-sm-3">
                      <input appShadowCheck formControlName="word_count" (keypress)="trans.patchValue({is_cat_changed: true}); nonNumericValPress($event);" type="number" min="0"
                        class="text-center form-control words" [attr.disabled]="(!checkTradosData || checkXTMData || checkPrhaseData) ? '' : null">
                    </div>
                    <div class="col-md-3 col-sm-3">
                      <input appShadowCheck formControlName="rate" type="number" min="0" readonly class="text-center form-control unit_rate">
                    </div>
                    <div class="col-md-3 col-sm-3">
                      <input appShadowCheck formControlName="amount" type="number" min="0" readonly class="text-center form-control sub_total">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row align-items-center pe-3">
            <div class="col-sm-8 p-0">
              <!-- <label><input formControlName="invoiceable" type="checkbox"> {{lang.getText('display_in_quote')}}</label> -->
              <button *ngIf="!projectEditDis"
                [disabled]="translationsFA.length == 0 || !checkTradosData || checkXTMData || checkPrhaseData"
                (click)="checkClaimed && checkTradosData && !checkXTMData  ? openImportCATModal(null, i) : false"
                class="btn btn-primary btn-sm cat-import-btn me-2">
                {{lang.getText('import_cat_analysis')}}
              </button>
              <button *ngIf="!projectEditDis"
                [disabled]="translationsFA.length == 0 || ( !checkTradosData || checkXTMData || checkPrhaseData) || trans.value.is_cat_changed"
                (click)="checkClaimed && checkTradosData && !checkXTMData ? updateCATtoAll(i) : false"
                class="btn btn-primary btn-sm cat-import-btn me-2">
                {{lang.getText('copy_and_save_analysis_to_all_language_combinations')}}
              </button>
              <button class="btn btn-sm cat-import-btn btn-primary me-2"
                type="button"
                *ngIf="is_claimed_and_xtm()"
                (click)="is_claimed_and_xtm() ?  getTranslationSnapshot(i) : false"
                [disabled]="! is_snapshot_available() || isOpenViewQuotingSnapshot">
                {{ lang.getText('view_translation_snapshot') }}
              </button>
              <button class="btn btn-sm cat-import-btn btn-warning"
                type="button"
                (click)="trans.get('financially_closed').value ? false : updateCAT('client', trans, i)"
                [attr.disabled]="trans.get('financially_closed').value ? '' : null">
                {{ lang.getText('save_and_close') }}
              </button>
            </div>
            <div class="col-sm-4 total_count_section p-0">
              <div class="d-flex align-items-center gap-2 text-center" style="margin-top: 15px;">
                <div class="col-sm-6 row badge-primary">
                  <div>
                    {{lang.getText('total_words')}}&nbsp;{{':'}}&nbsp;
                    {{trans.getRawValue().words_count}}
                    <input formControlName="words_count" readonly type="hidden" class="form-control">
                  </div>
                </div>
                <div class="col-sm-6 row badge-primary">
                  <div>
                    {{lang.getText('weighed_words')}}&nbsp;{{':'}}&nbsp;
                    {{trans.getRawValue().weighted_count}}
                    <input formControlName="weighted_count" readonly type="hidden" class="form-control">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</div>
<app-pagination
  theme = 'right-aligh-btn'
  (go_to_page) = "getPageOfQuoteLangTrans($event)"
  >
</app-pagination>
<ng-template #select_translation_rate_modal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">
    </h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="close_modal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="col-md-12">
    </div>
    <div class="col-md-12">
      <app-client-translation-rates
        [client]="client_id"
        [is_project_page]="true"
        [currency_id]="currency_id"
        [selected_tr_for_rate]="selected_tr_for_rate"
        (selected_tr_rate)="apply_translation_rate($event)"
        >
      </app-client-translation-rates>
    </div>
  </div>
</ng-template>
<div class="backdrop" [ngStyle]="{'display':display}"></div>
<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':display}" style="z-index: 9999;"> 
  <div class="modal-dialog" role="document">
    <div class="modal-content" [ngClass]="status">
      <div class="modal-header text-center">
        <em class="fa fa-check" style="padding-top: 4px;"></em> &nbsp;
        {{message}}
        <button type="button" class="close" aria-label="Close" (click)="closeModalDialog()"><span
            aria-hidden="true">&times;</span></button>
      </div>
    </div>
  </div>
</div>
