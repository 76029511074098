import { Component, OnInit } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Injector } from '@angular/core';
import { UntypedFormBuilder, UntypedFormArray, UntypedFormGroup } from "@angular/forms";
import { TokenService } from '../../services/token.service';
import { JwtauthService } from '../../services/jwtauth.service';
import { LanguageService } from "src/app/services/language.service";
import { ProjectLabelService } from "src/app/services/project-label.service";
import { LoaderService } from "src/app/services/loader.service";

@Component({
  selector: "app-project-label",
  templateUrl: "./project-label.component.html",
  styleUrls: ["./project-label.component.scss"],
})
export class ProjectLabelComponent implements OnInit {
  apiURL = '';
  labels;
  labelFg: UntypedFormGroup;
  labelFa: UntypedFormArray;
  isEditable: boolean = false;
  isShowLable: boolean = false;
  isAddedNew: boolean = false;
  currentSelectedIndex: number = 0;
  originalLabels: any[] = [];

  constructor(
    private injector: Injector,
    private http: HttpClient,
    private token: TokenService,
    private Jwtwis: JwtauthService,
    public lang: LanguageService,
    private fb: UntypedFormBuilder,
    private loaderSer: LoaderService,
    private labelSer: ProjectLabelService
    ) {
    this.apiURL = this.injector.get('API_URL');
  }

  ngOnInit() {
    this.labelFa = this.fb.array([]);
    this.labelFg = this.fb.group({
      labels: this.labelFa
    });
    this.labelFg.disable();
    this.getLabels();
    this.labelSer.currentLabels.subscribe(labels => {
      while (this.getLabelsFa().length > 0) {
        this.getLabelsFa().removeAt(0);
      }
      this.labels = labels;
      this.originalLabels = [...this.labels];
      labels.forEach(label => {
        let disable = this.labelFg.disabled;
        this.addLabel(label.id, label.name, label.color);
        if (disable) { this.labelFg.disable(); }
      });
    })
  }

  getLabelsFa() {
    return this.labelFg.get('labels') as UntypedFormArray;
  }

  addLabel(id, name, color, isNewAdd : boolean = false) {
    this.getLabelsFa().push(this.fb.group({
      id: id,
      name: name,
      color: color,
      delete: '',
      is_new_add: isNewAdd,
    }));
    if (isNewAdd) {
      this.isAddedNew = true;
      if (this.currentSelectedIndex == this.labels.length) {
        this.currentSelectedIndex = (this.labels.length + 1);
      } else {
        this.currentSelectedIndex = this.labels.length;
      }
    }
  }

  editLabel() {
    this.getLabels();
    this.labelFg.enable();
    this.isEditable = true;
    this.currentSelectedIndex = null;
    this.isAddedNew = false;
  }

  deleteLabel(li) {
    if ( ! confirm(this.lang.getText('are_you_sure')) ) {
      return;
    }
    this.labelFa.at(li).patchValue({ delete: 'yes' });
    this.isEditable = false;
  }

  saveLabels() {
    let formData = {
      token: this.token.get(),
      labels: this.labelFa.value
    };
    this.http.post(`${this.apiURL}user-project-labels/save`, formData).subscribe(
      data => {
        if (this.isAddedNew) {
          this.loaderSer.notifySourceSuccess.next('added_successfully');
        } else {
          this.loaderSer.notifySourceSuccess.next('updated_successfully');
        }
        this.getLabels();
        this.labelFg.disable();
        this.currentSelectedIndex = null;
        this.isEditable = false;
        this.isAddedNew = false;
      },
      error => this.handleError(error)
    );
  }

  getLabels() {
    this.labelSer.get();
  }

  handleResponse(data) {
    this.labels = data['result'];
  }

  handleError(error) {
    this.Jwtwis.handleError(error);
    this.currentSelectedIndex = null;
    this.isEditable = false;
    this.isAddedNew = false;
  }
  
  editSpecificLabel(label: any, index: number) {
    this.currentSelectedIndex = index;
  }
  cancelEdit(index: number) { 
    const originalValue = this.originalLabels[index];
    this.labelFa.at(index).patchValue({ name: originalValue.name });
    this.labelFa.at(index).patchValue({ color: originalValue.color });
    if (this.currentSelectedIndex == this.labels.length) {
        this.getLabelsFa().removeAt(this.currentSelectedIndex);
    }
    this.currentSelectedIndex = null;
    this.isEditable = false;
  }
  cancelAdd(index: number) {
        this.getLabelsFa().removeAt(index);
  }

  showLabel() {
    this.isShowLable = true;
  }
}


    