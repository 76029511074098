import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { configurationPageConfig } from 'src/app/configs/configuration.page.config';

@Component({
  selector: 'app-breadcrumb-menu',
  templateUrl: './breadcrumb.menu.component.html',
  styleUrls: ['./breadcrumb.menu.component.scss']
})
export class BreadcrumbMenuComponent implements OnInit {
  menu_groups: Array<Object>;
  breadcrumb_menus = [];
  public href: string = "";
  constructor(private router: Router) {
    //
  }

  ngOnInit(): void {
    let conf_page = new configurationPageConfig();
    this.menu_groups = conf_page.menu_groups;

    this.router.events.subscribe((val) => {
      this.breadcrumb_menus = [];
      let router_url = this.href = this.router.url;
      if ( ! this.show_menu(router_url) ) {
        return;
      }
      this.generate_breadcrumb(router_url);
    });
  }

  generate_breadcrumb(router_url) {
    this.breadcrumb_menus.push({
      label_key: 'configuration',
      href: '/configure'
    });
    this.menu_groups.forEach(group => {
      group['menus'].forEach(menu => {
        if (menu.sub_menus && router_url.includes('company-logo')) {
            this.breadcrumb_menus.push({
              label_key: group['lang_key'],
              href: false
            });
            this.breadcrumb_menus.push({
              label_key: menu.lang_key,
              href: menu.uri
            });
            this.breadcrumb_menus.push({
              label_key: menu.sub_menus[0].lang_key,
              href: menu.sub_menus[0].uri
            });
        } 
        else if (router_url.includes(menu.uri)) {
              this.breadcrumb_menus.push({
                label_key: group['lang_key'],
                href: false
              });
              this.breadcrumb_menus.push({
                label_key: menu.lang_key,
                href: menu.uri
              });
        }
      });
    });
    if (router_url.includes('invoice-model-v2') && !router_url.includes('invoice-model-v2/list')){
      this.breadcrumb_menus.push({
        label_key: 'financial_title',
        href: false
      });
      this.breadcrumb_menus.push({
        label_key: 'invoice_model',
        href: '/configure/invoice-model-v2/list'
      });
    }
  }

  show_menu(router_url): boolean {
    if ( router_url.startsWith("configure", 1) ) {
      return true;
    }
    return false;
  }
}
