import { Component, OnInit, ViewChild } from '@angular/core';
import { LanguageService } from '../services/language.service';
import { LoaderService } from '../services/loader.service';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  isLoading: Boolean = false;
  newDesign: Boolean = false;
  private notifier: NotifierService;
  private notifySourceSub: Subscription;
  private notifySourceSuccSub: Subscription;
  @ViewChild('notificationTemp', { static: true }) notificationTemp;

  constructor(
    private loaderSer: LoaderService,
    public lang_ser: LanguageService,
    public router: Router,
    notifier: NotifierService
  ) {
    this.notifier = notifier;
  }

  ngOnInit() {
    this.notifySourceSub = this.loaderSer.notifySourceError.subscribe(messageKey => {
      this.notifier.show({
        message: messageKey,
        type: "error",
        template: this.notificationTemp
      });
    });
    this.notifySourceSuccSub = this.loaderSer.notifySourceSuccess.subscribe(messageKey => {
      this.notifier.show({
        message: messageKey,
        type: "success",
        template: this.notificationTemp
      });
    });
    this.loaderSer.isLoadingSource.subscribe(val => {
      this.isLoading = val
    })
    this.router.events.subscribe(res => {
      if ( this.router.url.startsWith('/project') ) {
        this.newDesign = true;
      } else {
        this.newDesign = false;
      }
    });
  }

  ngOnDestroy() {
    this.notifySourceSub.unsubscribe();
    this.notifySourceSuccSub.unsubscribe();
  }
}
